import React, { useState, useEffect, memo, Fragment } from 'react';
import HtmlToReact from 'html-to-react';
import { api } from '../api/api';
import { Constantes } from '../common/Constantes';
import Modal from '../common/Modal';

import '../css/pages/promociones.css';

const ImagenPromocion = memo(({ promocion }) => {
  const [url, setUrl] = useState();
  useEffect(() => getImagen(), []);

  const getImagen = () => {
    api
      .get(
        `${Constantes.BASE}/v1/promociones/image/${promocion.ip}?pr=${promocion.pr}`,
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        setUrl(window.URL.createObjectURL(new Blob([response])));
      })
      .catch(() => {
        setUrl();
      });
  };
  return <img src={url} alt={promocion.np} />;
});

const ItemPromocion = ({ promocion }) => {
  const [open, setOpen] = useState(false);

  const parse = () => {
    let htmlParser = new HtmlToReact.Parser();
    return htmlParser.parse(promocion.tc);
  };

  return (
    <>
      <div className="op-promocion-item" onClick={() => setOpen(true)}>
        <label>{promocion.np}</label>
        <ImagenPromocion promocion={promocion} />
        <label>{`Desde ${promocion.fi} ${
          promocion.ff ? 'hasta ' + promocion.ff : ''
        }`}</label>
        <label>{`Código: ${promocion.cp}`}</label>
      </div>
      {open && (
        <Modal title={promocion.np} onClose={() => setOpen(false)}>
          {parse()}
        </Modal>
      )}
    </>
  );
};

const Promociones = () => {
  const [promociones, setPromociones] = useState([]);
  const [process, setProcess] = useState(false);
  const getPromociones = () => {
    setProcess(true);
    api
      .get(`${Constantes.BASE}/v1/promociones/disponibles/LAV`)
      .then((response) => setPromociones(response))
      .catch((error) => console.log(error))
      .finally(() => setProcess(false));
  };

  useEffect(() => {
    getPromociones();
  }, []);

  return (
    <div className="op-body-contenedor">
      <div className="op-fondo-promocion">
        <label>Promociones y descuentos</label>
      </div>
      <div className="op-promociones">
        {promociones.map((p) => (
          <ItemPromocion key={p.pr} promocion={p} />
        ))}
      </div>
    </div>
  );
};

export default Promociones;
