import React, { useRef, useEffect } from 'react';
import Proptypes from 'prop-types';
import lottie from 'lottie-web';

const AnimationIcon = ({ tipo, loop, ...rest }) => {
  const container = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop,
      autoplay: true,
      animationData: handleType(),
    });
  }, []);

  const handleType = () => {
    switch (tipo) {
      case 'code':
        return require('../assets/json/code.json');
      case 'devices':
        return require('../assets/json/devices.json');
      case 'desing':
        return require('../assets/json/web-designer.json');
      case 'mobile':
        return require('../assets/json/mobile-device.json');
      default:
        return require('../assets/json/mobile.json');
    }
  };
  return <div ref={container} {...rest}></div>;
};

AnimationIcon.propTypes = {
  tipo: Proptypes.string,
  loop: Proptypes.bool,
};

AnimationIcon.defaultProps = {
  tipo: 'default',
  loop: true,
};

export default AnimationIcon;
