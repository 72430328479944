import React, { useState } from 'react';
import Swal from 'sweetalert2';

import AnimationIcon from '../components/AnimationIcon';
import Bloque from '../components/Bloque';
import { Constantes } from '../common/Constantes';
import { api } from '../api/api';

import '../css/pages/inicio.css';

const Inicio = () => {
  const [process, setProcces] = useState(false);
  const [contacto, setContacto] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    errors: {
      name: '',
      email: '',
      phone: '',
      message: '',
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let errores = {
      name: '',
      email: '',
      phone: '',
      message: '',
    };

    switch (name) {
      case 'name':
        errores.name = validate(name, value);
        break;
      case 'email':
        errores.email = validate(name, value);
        break;
      case 'phone':
        errores.phone = validate(name, value);
        break;
      case 'message':
        errores.message = validate(name, value);
        break;
      default:
        break;
    }
    setContacto((prev) => ({
      ...prev,
      [name]: value,
      errors: errores,
    }));
  };

  const sendMail = () => {
    const formula = document.querySelector('.op-contacto .op-contenedor');
    const datos = formula.querySelectorAll('input');
    let correcto = true;
    datos.forEach((item) => {
      let valor = validate(item.name, item.value);
      if (item.type === 'text' && item.value === '' && valor !== '') {
        correcto = false;
      } else if (item.type !== 'checkbox' && valor !== '') {
        correcto = false;
      }
    });
    const message = formula.querySelector('textarea');
    let valor = validate(message.name, message.value);

    if (message.value === '' && valor !== '') {
      correcto = false;
    }
    if (!correcto) {
      Swal.fire({
        title: 'Mensaje',
        text: 'Debe llenar todos los datos del formulario',
        icon: 'warning',
        confirmButtonColor: '#00B181',
        confirmButtonText: 'Ok',
      });
    } else if (validateForm(contacto.errors)) {
      setProcces(true);
      let request = {
        ie: Constantes.TOKEN,
        no: contacto.name,
        uc: contacto.email,
        nt: contacto.phone,
        ms: contacto.message,
      };
      api
        .post(`${Constantes.BASE}/v1/webs/informar`, request)
        .then(() => {
          handleClean();
          Swal.fire({
            icon: 'success',
            title: 'Correo enviado',
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch(() => {
          Swal.fire({
            title: 'Mensaje',
            text: 'Error al enviar el correo',
            icon: 'error',
            confirmButtonColor: 'red',
            confirmButtonText: 'Ok',
          });
        })
        .finally(() => setProcces(false));
    }
  };

  const handleClean = () => {
    setContacto({
      name: '',
      email: '',
      phone: '',
      message: '',
      errors: {
        name: '',
        email: '',
        phone: '',
        message: '',
      },
    });
  };

  const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
      // if we have an error string set valid to false
      (val) => val.length > 0 && (valid = false)
    );
    return valid;
  };

  const validate = (name, value) => {
    switch (name) {
      case 'name':
        const validNameRegex = new RegExp(
          /^[a-zA-Z0-9\s\u00e1\u00e9\u00ed\u00f3\u00fa\u00c1\u00c9\u00cd\u00d3\u00da\u00f1\u00d1]{3,30}$/
        );
        return value.length < 3
          ? 'Ingrese su nombre'
          : !validNameRegex.test(value)
          ? 'No debe ingresar caracteres extraños'
          : '';
      case 'email':
        const validEmailRegex = RegExp(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|("."))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        );
        return !validEmailRegex.test(value) ? 'Email inválido' : '';
      case 'phone':
        const validPhoneRegex = new RegExp(/^[0-9\b]{8,9}$/);
        return !validPhoneRegex.test(value) ? 'Teléfono inválido' : '';
      case 'message':
        const validTextRegex = new RegExp(
          /^[a-zA-Z0-9-/.:+,\s\u00e1\u00e9\u00ed\u00f3\u00fa\u00c1\u00c9\u00cd\u00d3\u00da\u00f1\u00d1]{3,500}$/
        );
        return value.length < 3
          ? 'Ingrese su mensaje'
          : !validTextRegex.test(value)
          ? 'No debe ingresar caracteres extraños'
          : '';
      default:
        return '';
    }
  };

  return (
    <div className="op-body-contenedor">
      <div className="op-inicio">
        <div className="op-content">
          <h1>Desarrollo y Consultor&iacute;a de Software</h1>
          <label>
            Contamos con consultores y desarrolladores altamente especializados
            para la construcci&oacute;n e implementaci&oacute;n de soluciones
            inform&aacute;ticas
          </label>
        </div>
        <AnimationIcon className="code" tipo={'code'} />
        <AnimationIcon className="mobile" />
      </div>
      <div className="op-servicios">
        <div className="op-content">
          <h1>Nuestros Servicios</h1>
          <label>
            Empresa peruana dedicada al Desarrollo y Consultor&iacute;a de
            Software
          </label>
        </div>
        <div className="op-items">
          <div className="op-item web">
            <div className="icon"></div>
            <label>Websites</label>
            <p>
              Especializados en desarrollo de Software, aplicaciones web
              empresariales y de gesti&oacute;n
            </p>
          </div>
          <div className="op-item mobile">
            <div className="icon"></div>
            <label>Aplicaciones M&oacute;viles</label>
            <p>
              Creaci&oacute;n de aplicaciones para tu negocio para siempre estar
              conectado a tus clientes
            </p>
          </div>
          <div className="op-item consulting">
            <div className="icon"></div>
            <label>Consultor&iacute;a</label>
            <p>
              Implementaci&oacute;n de estrategias y soluciones digitales para
              tu negocio
            </p>
          </div>
        </div>
      </div>
      <div className="op-clientes">
        <a
          href="https://www.emsag-peru.com/"
          target={'_blank'}
          rel={'noopener noreferrer'}
          className="client emsag"
        />
        <a
          href="https://patcoperu.com/"
          target={'_blank'}
          rel={'noopener noreferrer'}
          className="client patco"
        />
      </div>
      <Bloque
        titulo="Dise&ntilde;amos Website a tu medida"
        backgroundColor="#f3f5f9"
        reverse
      >
        <AnimationIcon tipo={'desing'} loop={false} />
      </Bloque>
      <Bloque titulo="Dise&ntilde;amos para platormas m&oacute;viles, tambi&eacute;n">
        <AnimationIcon tipo={'mobile'} loop={false} />
      </Bloque>
      <Bloque
        titulo="Hacemos que tu sistema funcione en todos los dispositivos"
        backgroundColor="#f3f5f9"
        reverse
      >
        <AnimationIcon tipo={'devices'} />
      </Bloque>
      <Bloque titulo="Trabajemos juntos">
        <div className="op-contacto">
          <form id="idFormulario" className="op-contenedor">
            <div className="texto col-2">
              {contacto.errors.name.length > 0 && (
                <span>{contacto.errors.name}</span>
              )}
              <input
                name="name"
                value={contacto.name}
                type="text"
                required="required"
                maxLength={30}
                onChange={handleChange}
              />
              <label>Nombres</label>
            </div>
            <div className="texto col-2">
              {contacto.errors.phone.length > 0 && (
                <span>{contacto.errors.phone}</span>
              )}
              <input
                name="phone"
                value={contacto.phone}
                type="text"
                required="required"
                maxLength={9}
                onChange={handleChange}
              />
              <label>Tel&eacute;fono</label>
            </div>
            <div className="texto">
              {contacto.errors.email.length > 0 && (
                <span>{contacto.errors.email}</span>
              )}
              <input
                name="email"
                value={contacto.email}
                type="text"
                required="required"
                maxLength={80}
                onChange={handleChange}
              />
              <label>Correo</label>
            </div>
            <div className="texto">
              {contacto.errors.message.length > 0 && (
                <span>{contacto.errors.message}</span>
              )}
              <textarea
                name="message"
                required="required"
                value={contacto.message}
                rows={5}
                onChange={handleChange}
              />
              <label>Mensaje</label>
            </div>
            <div className="texto accion">
              <button
                type="button"
                disabled={process}
                onClick={() => sendMail()}
              >
                {process ? (
                  <>
                    <i className="fa fa-spinner fa-pulse fa-fw"></i> Enviando
                  </>
                ) : (
                  <>Enviar</>
                )}
              </button>
            </div>
          </form>
        </div>
      </Bloque>
    </div>
  );
};

export default Inicio;
