import React from 'react';

import '../css/pages/soporte.css';

const Soporte = () => {
  return (
    <div className="op-body-contenedor">
      <div className="op-fondo-soporte">
        <label>Soporte</label>
        <span>
          ¿Necesitas ayuda? Puedes escribirnos a trav&eacute;s de estas
          plataformas
        </span>
        <div className="op-redes">
          <i
            className="fa fa-facebook-square fa-lg"
            aria-hidden="true"
            style={{ color: '#0574e7' }}
          ></i>
          <a href="https://www.facebook.com/Wuasho" target={'_blank'}>
            Facebook
          </a>
          <i
            className="fa fa-instagram fa-lg"
            aria-hidden="true"
            style={{ color: '#c32d7d' }}
          ></i>
          <a href="https://www.instagram.com/wuashoapp/" target={'_blank'}>
            Instagram
          </a>
        </div>
      </div>
    </div>
  );
};

export default Soporte;
