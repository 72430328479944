import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Inicio from './pages/Inicio';
import Aplicacion from './pages/Aplicacion';
import Promociones from './pages/Promociones';
import Wuasho from './pages/Wuasho';
import Empresa from './pages/Empresa';
import Soporte from './pages/Soporte';
import ConfirmarCliente from './pages/ConfirmarCliente';

import Footer from './components/Footer';
import Header from './components/Header';

import './css/general.css';

const App = () => {
  return (
    <BrowserRouter>
      <Header />
      <div className="op-body">
        <Routes>
          <Route path="/confirmar-cliente" element={<ConfirmarCliente />} />
          <Route
            path="/aplicacion"
            element={
              <>
                <Wuasho />
                <Footer />
              </>
            }
          />
          <Route
            path="/promociones"
            element={
              <>
                <Promociones />
                <Footer />
              </>
            }
          />
          <Route
            path="/terminos"
            element={
              <>
                <Aplicacion />
                <Footer />
              </>
            }
          />
          <Route
            path="/empresa"
            element={
              <>
                <Empresa />
                <Footer />
              </>
            }
          />
          <Route
            path="/soporte"
            element={
              <>
                <Soporte />
                <Footer />
              </>
            }
          />
          <Route
            path="/"
            element={
              <>
                <Inicio />
                <Footer />
              </>
            }
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
