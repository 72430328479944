import React from 'react';
import Proptypes from 'prop-types';

import '../css/common/modal.css';

const Modal = (props) => {
  return (
    <div className="op-content-modal">
      <div className={`op-modal${props.size ? ' op-' + props.size : ''}`}>
        <div className="op-modal-header">
          <span>{props.title}</span>
          <div
            className={`op-modal-close${
              props.processing === true ? ' disabled' : ''
            }`}
            onClick={() => props.onClose()}
          ></div>
        </div>
        <div className="op-modal-body">
          <div className="op-form">{props.children}</div>
        </div>
        {props.onActions && (
          <div className="op-modal-footer">{props.onActions()}</div>
        )}
      </div>
    </div>
  );
};

Modal.propTypes = {
  title: Proptypes.string.isRequired,
  children: Proptypes.any.isRequired,
  onClose: Proptypes.func.isRequired,
  size: Proptypes.oneOf(['small', 'medium', 'large']),
  onActions: Proptypes.func,
  processing: Proptypes.bool,
  closePressEscape: Proptypes.bool,
  loading: Proptypes.string,
};

Modal.defaultProps = {
  processing: false,
  closePressEscape: true,
  loading: 'default',
};

export default Modal;
