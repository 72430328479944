import React from 'react';

import '../css/components/bloque.css';

const Bloque = (props) => {
  return (
    <div className={`op-bloque-contenedor ${props.reverse ? 'reverse' : ''}`}>
      <div
        className="overlay"
        style={{
          backgroundColor: props.backgroundColor
            ? props.backgroundColor
            : '#ffffff',
        }}
      ></div>
      <div className="texto">
        <div>
          {props.titulo}
          {props.content && props.content()}
        </div>
      </div>
      <div className="imagen">{props.children}</div>
    </div>
  );
};

export default Bloque;
