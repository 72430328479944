import React from 'react';

import '../css/components/footer.css';

const Footer = () => {
  return (
    <div className="op-footer">
      <div>
        <i className="fa fa-code" aria-hidden="true"></i>
        <span>Soluciones OP</span>
      </div>
      <div>
        <i className="fa fa-envelope-o" aria-hidden="true"></i>
        <span>informes@solucionesop.com</span>
      </div>
    </div>
  );
};

export default Footer;
