import React, { useEffect, useState } from 'react';

import Swal from 'sweetalert2';

import { Constantes } from '../common/Constantes';
import { api } from '../api/api';

import Loading from '../components/Loading';

import '../css/pages/confirmar.css';
import { useSearchParams } from 'react-router-dom';

const ConfirmarCliente = (props) => {
  const [params] = useSearchParams();
  const cl = params.get('cl');
  const au = params.get('au');

  const [process, setProcess] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    validateConfirmation(cl, au);
  }, []);

  const validateConfirmation = (cl, au) => {
    setProcess(true);
    api
      .patch(`${Constantes.BASE}/v1/clientes/confirmar?cl=${cl}&au=${au}`)
      .then(() => {
        setMessage(
          'Felicitaciones su confirmación fue exitosa, por favor verificar su correo'
        );
      })
      .catch(() => {})
      .finally(() => setProcess(false));
  };
  return (
    <div className="op-content-confirmar">
      <div className="op-confirmar">
        <h1>Confirmaci&oacute;n de registro</h1>
        <h3>Bienvenido a soluciones OP</h3>
        <p>{message}</p>
        <h5>@2022 Soluciones OP - Todos los derechos reservados</h5>
      </div>
      {process && <Loading />}
    </div>
  );
};

export default ConfirmarCliente;
