import React from 'react';
import { Link } from 'react-router-dom';

import Bloque from '../components/Bloque';

import mobil from '../assets/images/mobile-app.png';
import primerFondo from '../assets/images/mobile-app_81.png';
import descarga from '../assets/images/mobile-app_91.png';
import apple from '../assets/images/appstore.png';
import google from '../assets/images/google-play.png';
import '../css/pages/wuasho.css';

const Wuasho = () => {
  return (
    <div className="op-body-contenedor">
      <div className="op-wuasho">
        <div className="op-titulo">
          <label>Wuasho app</label>
          <span>Las mejores lavander&iacute;as en un solo lugar</span>
          <span>Encuentra promociones y descuentos exclusivos</span>
          <span>Elige el horario que m&aacute;s te convenga</span>
        </div>
        <div className="op-fondo-wuasho">
          <div>
            <img src={mobil} />
          </div>
        </div>
      </div>
      <Bloque
        titulo="Encuentra las mejores promociones"
        backgroundColor="#f3f5f9"
        reverse
        content={() => (
          <Link to="/promociones" className="op-boton">
            Promociones y Descuentos
          </Link>
        )}
      >
        <div className="op-wuasho-promo">
          <img src={primerFondo} alt="Wuasho nuevos" />
        </div>
      </Bloque>
      <Bloque
        titulo="Descarga gratis"
        backgroundColor="#f3f5f9"
        content={() => (
          <div className="op-wuasho-descargas">
            <a href="https://apple.co/3xhLXNq" target={'_blank'}>
              <img src={apple} alt="Wuasho descarga" />
            </a>
            <a href="https://bit.ly/3uJ2NlA" target={'_blank'}>
              <img src={google} alt="Wuasho descarga" />
            </a>
          </div>
        )}
      >
        <div className="op-wuasho-promo">
          <img src={descarga} alt="Wuasho descarga" />
        </div>
      </Bloque>
      <div className="op-terminos">
        <Link to={'/terminos'}>T&eacute;rminos y Condiciones</Link>
      </div>
    </div>
  );
};

export default Wuasho;
