import React, { useEffect, useRef } from 'react';
import Proptypes from 'prop-types';
import lottie from 'lottie-web';

import '../css/components/loading.css';

const Loading = ({ tipo }) => {
  const container = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('../assets/json/loading.json'),
    });
  }, []);

  return (
    <div className="op-content-loader">
      <div className="container" ref={container}></div>
    </div>
  );
};

Loading.propTypes = {
  tipo: Proptypes.string,
};

Loading.defaultProps = {
  tipo: 'default',
};

export default Loading;
